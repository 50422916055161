import React, { useState, useEffect } from 'react';
import { Instagram, Linkedin, FileJson } from 'lucide-react';

const ExpandIcon = ({ isExpanded, onClick }) => (
  <button
    onClick={onClick}
    aria-label={isExpanded ? 'Collapse' : 'Expand'}
    className="inline-flex items-center justify-center w-4 h-4 mr-1 text-xs text-gray-600 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500"
  >
    {isExpanded ? '−' : '+'}
  </button>
);

const JsonNode = ({ data, level = 0, keyName = null }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (typeof data !== 'object' || data === null) {
    return (
      <div className="ml-4">
        {keyName && <span className="font-semibold text-blue-700">{keyName}: </span>}
        <span className="text-green-600">{JSON.stringify(data)}</span>
      </div>
    );
  }

  const toggleExpand = () => setIsExpanded(!isExpanded);
  const isArray = Array.isArray(data);
  const bracketType = isArray ? '[]' : '{}';

  return (
    <div className="ml-4">
      <div className="flex items-center">
        <ExpandIcon isExpanded={isExpanded} onClick={toggleExpand} />
        <span className="text-purple-600 font-mono">{bracketType}</span>
        {keyName && <span className="font-semibold text-blue-700 ml-1">{keyName}</span>}
      </div>
      {isExpanded && (
        <div className="mt-1">
          {Object.entries(data).map(([key, value], index) => (
            <JsonNode key={key} data={value} level={level + 1} keyName={key} />
          ))}
        </div>
      )}
    </div>
  );
};

const JsonViewer = () => {
  const [jsonText, setJsonText] = useState('');
  const [jsonData, setJsonData] = useState(null);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('text');

  useEffect(() => {
    // Update the document title
    document.title = 'JSON Viewer - Visualize and Explore JSON Data';

    // Add meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'A user-friendly JSON viewer to visualize, explore, and validate JSON data structures.');
    } else {
      const newMetaDescription = document.createElement('meta');
      newMetaDescription.name = 'description';
      newMetaDescription.content = 'A user-friendly JSON viewer to visualize, explore, and validate JSON data structures.';
      document.head.appendChild(newMetaDescription);
    }
  }, []);

  const handleTextChange = (e) => {
    setJsonText(e.target.value);
    try {
      const parsed = JSON.parse(e.target.value);
      setJsonData(parsed);
      setError(null);
    } catch (err) {
      setJsonData(null);
      setError('Invalid JSON: ' + err.message);
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto flex flex-col min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
          <h1 className="text-2xl font-bold text-gray-900 flex items-center">
            <FileJson className="mr-2" /> JSON Viewer
          </h1>
        </div>
      </header>
      <main className="flex-grow p-4">
        <div className="bg-white shadow rounded-lg overflow-hidden">
          <div className="flex border-b">
            <button
              className={`py-3 px-6 ${activeTab === 'text' ? 'bg-blue-50 text-blue-700 border-b-2 border-blue-500 font-medium' : 'text-gray-500 hover:text-gray-700'}`}
              onClick={() => setActiveTab('text')}
            >
              Text Input
            </button>
            <button
              className={`py-3 px-6 ${activeTab === 'viewer' ? 'bg-blue-50 text-blue-700 border-b-2 border-blue-500 font-medium' : 'text-gray-500 hover:text-gray-700'}`}
              onClick={() => setActiveTab('viewer')}
            >
              JSON Viewer
            </button>
          </div>
          <div className="p-4">
            {activeTab === 'text' ? (
              <textarea
                placeholder="Paste your JSON here"
                value={jsonText}
                onChange={handleTextChange}
                className="w-full h-96 p-3 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                aria-label="JSON input textarea"
              />
            ) : (
              <div>
                {error ? (
                  <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded" role="alert">
                    <p className="font-bold">Error</p>
                    <p>{error}</p>
                  </div>
                ) : jsonData ? (
                  <div className="bg-gray-100 p-4 rounded-md shadow-inner font-mono text-sm overflow-auto max-h-96">
                    <JsonNode data={jsonData} />
                  </div>
                ) : (
                  <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 rounded" role="status">
                    <p className="font-bold">No JSON data</p>
                    <p>Enter valid JSON in the text input to visualize it here.</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
      <footer className="bg-white border-t border-gray-200 mt-8">
        <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center space-x-6">
            <a href="https://www.instagram.com/niru.class/" target="_blank" rel="noopener noreferrer" className="text-pink-600 hover:text-pink-700 transition-colors">
              <Instagram size={24} aria-label="Instagram" />
            </a>
            <a href="https://www.linkedin.com/in/nirvik99/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-700 transition-colors">
              <Linkedin size={24} aria-label="LinkedIn" />
            </a>
          </div>
          <p className="mt-4 text-center text-gray-500 text-sm">
            © 2024 JSON Viewer. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
};

export default JsonViewer;