import React from 'react';
import JsonViewer from './JsonViewer';
import './App.css';

function App() {
  return (
   
      <JsonViewer />
    
  );
}

export default App;